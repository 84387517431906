// ////////////////////////////スマホ/タブレット判別
var _ua = (function (u) {
  return {
    Tablet:
      (u.indexOf('windows') != -1 && u.indexOf('touch') != -1) ||
      u.indexOf('ipad') != -1 ||
      (u.indexOf('android') != -1 && u.indexOf('mobile') == -1) ||
      (u.indexOf('firefox') != -1 && u.indexOf('tablet') != -1) ||
      u.indexOf('kindle') != -1 ||
      u.indexOf('silk') != -1 ||
      u.indexOf('playbook') != -1,
    Mobile:
      (u.indexOf('windows') != -1 && u.indexOf('phone') != -1) ||
      u.indexOf('iphone') != -1 ||
      u.indexOf('ipod') != -1 ||
      (u.indexOf('android') != -1 && u.indexOf('mobile') != -1) ||
      (u.indexOf('firefox') != -1 && u.indexOf('mobile') != -1) ||
      u.indexOf('blackberry') != -1,
  };
})(window.navigator.userAgent.toLowerCase());
// if(_ua.Mobile){}

var osVer;
osVer = 'Android';

var ua = navigator.userAgent.toLowerCase();
var iPadFlag = false;
if (/android|ipod|ipad|iphone|macintosh/.test(ua) && 'ontouchend' in document) {
  iPadFlag = true;
  $('#wrapper').addClass('setTouch');
}
// if (navigator.userAgent.indexOf(osVer)>0){
// }

var breakNum = 768;
var tabletNum = 1024;
// ////////////////////////////////////init
// $(function () {
//   // setScroll();
//   // if ($('#wrapper').hasClass('home')) {
//   //   setWave();
//   // }
//   // ///////////
//   // if (!_ua.Mobile && !_ua.Tablet) {
//   //   $('#wrapper').addClass('desktop');
//   //   if (!$('#wrapper').hasClass('about')) {
//   //     $('html, body').prop({
//   //       scrollTop: 0,
//   //     });
//   //     if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1 && ua.indexOf('edge') === -1) {
//   //       // ここにSafari用の記述
//   //     } else {
//   //     }
//   //   }
//   // }
//   // ///////////
// });

$(window).on('load', function () {
  judgeWinSize();
  setHeader();
  setSpan();
  // setInview();
  // setAcc();
  // setMegaMenu();
  startScroll();
  // setMainMenu();
  setLoaded();
  // if ($('#wrapper').hasClass('home')) {
  //   switchMovie();
  // setSlider_01();
  //   setKv();
  // } else if ($('#wrapper').hasClass('project')) {
  //   setSlider_02();
  //   setProjectPost();
  //   setProjectThumb();
  // }
  setWay();
  if ($('#outerMenu').hasClass('effect_03')) {
    setSlider();
  } else {
    setGalleryScroll();
  }
  // setRellax();
  mailTo();
  // setBlobsSlide(6000);
});

var current_scrollY = 0;

//スライダー
function setSlider() {
  const mySwiperMain = new Swiper('.carouselStyle_01 .swiper-main', {
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    // centeredSlides: true,
    loop: true, // 無限ループさせる
    loopAdditionalSlides: 1, // 無限ループさせる場合に複製するスライド数
    // pagination: {
    //   el: '.swiper-pagination',
    //   type: 'fraction', // 枚数表示
    //   // clickable: true,
    // },
    mousewheel: {
      forceToAxis: true,
    },
    autoplay: {
      // 自動再生させる
      delay: 6000, // 次のスライドに切り替わるまでの時間（ミリ秒）
      disableOnInteraction: false, // ユーザーが操作しても自動再生を止めない
      waitForTransition: false, // アニメーションの間も自動再生を止めない（最初のスライドの表示時間を揃えたいときに）
    },
    speed: 1000, // スライドアニメーションのスピード（ミリ秒）
    // breakpoints: {
    //   // スライドの表示枚数：500px以上の場合
    //   769: {
    //     slidesPerView: 1.2,
    //   },
    // },
  });
  // 初期のスライド番号を設定
  updateCounter(mySwiperMain.realIndex + 1);

  // スライドが変わるたびにカウンターを更新
  mySwiperMain.on('slideChange', function () {
    updateCounter(mySwiperMain.realIndex + 1);
  });

  function updateCounter(index) {
    $('.counter span').text(index);
  }
}

//Gallery制御
function setGalleryScroll() {
  var isScrolling = false;

  $(window).scroll(function () {
    if (isScrolling) {
      // アニメーションが実行中の場合は何もしない
      return;
    }

    if ($(window).scrollTop() + $(window).height() >= $(document).height()) {
      isScrolling = true; // スクロール処理開始

      // ページの一番上に即座にジャンプ
      $(window).scrollTop(0);

      // 小さなタイムアウトを設定して、連続したスクロールイベントが発生しないようにする
      setTimeout(function () {
        isScrolling = false; // スクロール処理完了
      }, 100);
    }
  });
}

//メアドスパム対策
function mailTo() {
  $('.mailTo').click(function () {
    var address = $(this).attr('href').replace('+', '@');
    location.href = 'mailto:' + address;
    return false;
  });
}

// ///////ヘッダー制御
let set_position = 0;
function setHeader() {
  var timer = false;
  $(window).on('resize', function () {
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      $('header').removeClass('fixedHeader');
      $('header').removeClass('fixedHeaderDelay');
    }, 10);
  });

  fixedHeader();
  // console.log($('#mainVis').height())

  $(window).scroll(function () {
    fixedHeader();
  });

  function fixedHeader() {
    if ($('#wrapper').hasClass('home')) {
      h = $('#mainVis').height() / 2;
    } else if ($('#wrapper').hasClass('setKv')) {
      h = $('#kv').height();
    } else {
      h = 5;
    }
    baseHeight = h;
    if ($(this).scrollTop() < baseHeight) {
      $('#wrapper').removeClass('fixedHeader');
    } else if ($(this).scrollTop() >= baseHeight) {
      $('#wrapper').addClass('fixedHeader');
    }
    if (set_position < $(this).scrollTop()) {
      $('#wrapper').addClass('scrollDown');
      $('#wrapper').removeClass('scrollUp');
    } else {
      $('#wrapper').addClass('scrollUp');
      $('#wrapper').removeClass('scrollDown');
    }

    set_position = $(this).scrollTop();
  }
}

// テキストアニメーション用span生成
function setSpan() {
  $('.txEffect>span').each(function () {
    var text = $.trim(this.textContent),
      html = '';

    text.split('').forEach(function (v) {
      html += '<span>' + v + '</span>';
    });

    this.innerHTML = html;
  });
}

// ///////waypoint
function setWay() {
  $('.way,.way-slow,.alphaWay,.js-way').waypoint(
    function (direction) {
      var activePoint = $(this.element);
      if (direction === 'down') {
        // scroll down
        activePoint.addClass('is-active');
        timer = setTimeout(function () {
          activePoint.addClass('is-active-after');
        }, 500);
      }
    },
    {
      offset: '70%',
    }
  );

  $(window).scroll(function () {
    var windowTop = $(window).scrollTop();
    var windowBottom = windowTop + $(window).height();

    $('.toggle-way').each(function () {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();
      var counter = $(this).data('slide');
      if (elementBottom > windowTop && elementTop < windowBottom) {
        // 要素がビューポート内に見える場合
        $(this).addClass('is-active');
        $('.counter span').text(counter);
      } else {
        // 要素がビューポート内に見えない場合
        $(this).removeClass('is-active');
      }
    });
  });
}

// function scrollAddClass() {
//   const scrollEffect = document.querySelectorAll('.toggle-way');
//   let windowHeight = window.innerHeight * 0.8;
//   for (let i = 0; i < scrollEffect.length; i++) {
//     target = scrollEffect[i].getBoundingClientRect().top;
//     if (target < windowHeight) {
//       scrollEffect[i].classList.add('is-active');
//     } else {
//       scrollEffect[i].classList.remove('is-active');
//     }
//   }
// }
// document.addEventListener('DOMContentLoaded', scrollAddClass);
// document.addEventListener('scroll', scrollAddClass);

// ////////////////////////////アコーディオン
function setAcc() {
  // $('.js-toggle .js-ttl').on('click', function () {
  //   $(this).parent().toggleClass('active');
  //   $(this).next().stop().slideToggle('fast');
  // });
}

// //////////////////////////////ロード完了
function setLoaded() {
  $('#wrapper,#loading').addClass('loaded');
  loadStart();
}

function loadStart() {
  timer = setTimeout(function () {
    $('#wrapper,#loading').addClass('loadedDone');
  }, 200);
  timer = setTimeout(function () {
    $('#wrapper,#loading').addClass('loadedStart');
  }, 600);
  timer = setTimeout(function () {
    $('#wrapper').addClass('loadEnd');
    $('#mainVis').addClass('is-active');
  }, 1500);
  timer = setTimeout(function () {
    $('#wrapper').addClass('homeStart');
  }, 1200);
  if ($('#wrapper').hasClass('home')) {
    timer = setTimeout(function () {
      $('#wrapper').addClass('homeDelay');
    }, 2200);
  } else {
    timer = setTimeout(function () {
      $('#wrapper').addClass('pagesDelay');
    }, 500);
  }
  // $('body,html').animate({
  //   scrollTop: 0
  // }, 0, 'swing')
}

// ////////////ウィンドウサイズを判別
function judgeWinSize() {
  var winW = $(window).width();
  if (winW > breakNum) {
    $('#wrapper').addClass('setPc');
  } else {
    $('#wrapper').addClass('setSp');
  }

  var timer = false;
  var currentWidth = window.innerWidth;
  window.addEventListener('resize', function () {
    if (currentWidth == window.innerWidth) {
      return;
    }
    currentWidth = window.innerWidth;
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      winW = $(window).width();
      if (winW > breakNum) {
        $('#wrapper').addClass('setPc');
        $('#wrapper').removeClass('setSp');
      } else {
        $('#wrapper').addClass('setSp');
        $('#wrapper').removeClass('setPc');
      }
    }, 200);
  });
}

// /////////////////////////////スムーススクロール
function setScroll() {
  var headerHeight = $('header').height();
  // var headerHeight = $('header .col-main').height() + 50;
  // if ($('#wrapper').hasClass('setPc')) {
  //   var headerHeight = 0;
  // } else {
  //   var headerHeight = 100;
  // }
  $('a[href^="#"]').click(function (e) {
    var href = $(this).attr('href');
    var target = $(href == '#' || href == '' ? 'html' : href);
    // var position = target.offset().top;

    if ($(this).hasClass('unqNav')) {
      closeFnc();
    }
    setTimeout(function () {
      var position = target.offset().top - headerHeight;
      $('html, body').animate(
        {
          scrollTop: position,
        },
        400,
        'swing'
      );
    }, 100);
    e.preventDefault();
    // $.when(
    //   $('html, body').animate(
    //     {
    //       scrollTop: position,
    //     },
    //     400,
    //     'swing'
    //   ),
    //   e.preventDefault()
    // ).done(function () {
    //   var diff = target.offset().top;
    //   if (diff === position) {
    //   } else {
    //     $('html, body').animate(
    //       {
    //         scrollTop: diff,
    //       },
    //       400,
    //       'swing'
    //     );
    //   }
    // });
  });
}

function startScroll() {
  // ページのURLを取得
  const url = $(location).attr('href'),
    // headerの高さを取得してそれに30px追加した値をheaderHeightに代入
    headerHeight = $('header').outerHeight() + 30;

  // urlに「#」が含まれていれば
  if (url.indexOf('#') != -1) {
    // urlを#で分割して配列に格納
    const anchor = url.split('#'),
      // 分割した最後の文字列（#◯◯の部分）をtargetに代入
      target = $('#' + anchor[anchor.length - 1]),
      // リンク先の位置からheaderHeightの高さを引いた値をpositionに代入
      position = Math.floor(target.offset().top) - headerHeight;
    // positionの位置に移動
    $('html, body').animate({ scrollTop: position }, 500);
  }
  setScroll();
}

// ////////////////////////////////////ランドスケープ判定
var isLandscape = function () {
  if (window.innerHeight > window.innerWidth) {
    jQuery('body').addClass('portrait');
    jQuery('body').removeClass('landscape');
  } else {
    jQuery('body').addClass('landscape');
    jQuery('body').removeClass('portrait');
  }
};

if (_ua.Mobile) {
  jQuery(window).resize(function () {
    isLandscape();
  });
  isLandscape();
}

function watchViewMode() {
  window.addEventListener('orientationchange', function () {
    // orientationchange イベントがトリガーされたときの処理をここに記述します
    location.reload(); // ページをリロードします
  });
}
